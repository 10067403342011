/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/tailwind.css";

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
}) => {
  if (prevRouterProps?.location?.pathname === location.pathname) {
    return false;
  }

  return true;
};
